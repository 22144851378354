<template>
    <div class="mb-3">
        <h3 class="mb-4 text-dark">Add-ons</h3>

        <div class="row">
            <div class="col-12 p-0 mb-3 addon-container" v-for="addon in availableAddons" :class="{'selected-addon' : addonSelected(addon)}">

                <div class="d-md-flex">
                    <div class="addon-title">
                        <h5 class="mb-4">{{ addon.name }}</h5>

                        <button @click.prevent="addOn(addon)" class="w-100">
                            <span v-if="addonSelected(addon)">Added</span>
                            <span v-else>Add now</span>
                            <span> + R{{ addon.price }} per month (from {{ formartDate(addon.start_date) }})</span>

                            <span v-if="addonSelected(addon)" class="text-danger">Remove</span>
                        </button>
                    </div>

                    <div class="addon-description">
                        <p>{{ addon.description }}</p>

                        <p v-if="addon.info_link" class="mb-0">Click <a :href="addon.info_link" target="_blank">here</a> for more info.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name: "AddOns",
    props: {
        availableAddons: {
            type: Array,
            required: true
        },
        selectedAddons: {
            type: Array,
            required: true
        }
    },
    methods: {
        addOn(addon) {
            this.$emit('addon', addon);
        },
        addonSelected(addon) {
            return this.selectedAddons.includes(addon.id);
        },
        formartDate(date) {
            return moment(date).format('MMMM YYYY');
        }
    }
}
</script>
<style scoped lang="scss">
@import '../../../sass/abstract/colours.scss';

.addon-title {
    background-color: $uctDarkBlue;
    min-width: 240px;
    color: $white;
    text-align: center;
    border-radius: 20px;
    align-content: center;
    padding: 10px;

    button {
        background-color: $white;
        color: $uctDarkBlue;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        font-size: 14px;
    }
}

.addon-description {
    padding: 20px;
}

.addon-container {
    border-radius: 20px;
    transition: all 0.5s ease-in-out;

    &.selected-addon {
        background-color: $pastel-light-blue;
        color: $white;
    }
}

.selected-addon {
    background-color: $pastel-light-blue;
    color: $white;
}
</style>
