<template>
    <div class="cg mt-3">
        <a class="cg-header" :href="info.addon.info_link" target="_blank">Connect & Grow Programme</a>

        <div class="cg-img">
            <img :src="'/portal-data/uct/connect-and-grow.png'" alt="Connect and grow">
        </div>

        <div class="cg-info-container">
            <p class="cg-description">{{ info.addon.description }}</p>

            <p class="cg-info-link">Click <a :href="info.addon.info_link" target="_blank">here</a> for more info.</p>

            <div v-if="!signedUp" class="cg-buttons">
                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'text-capitalize': true,
                        'fs-small': true,
                    }"
                    :buttonText="''"
                    :loading="loading"
                    @button-click="() => signup()"
                >
                    Add now <br> (+R{{ info.addon.price }}) per month from {{ formartDate(info.addon.start_date) }}
                </vi-button-spinner>
            </div>

            <vi-alert
                class="cg-alert alert alert-success fs-small"
                v-if="signedUp"
                :type="''"
            >
                You have successfully signed up for the Connect & Grow Programme.
            </vi-alert>

            <div v-if="signedUp" class="cg-buttons">
                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'text-capitalize': true,
                        'fs-small': true,
                        'mb-3': true,
                    }"
                    :buttonText="'I\'ve changed my mind'"
                    :loading="loading"
                    @button-click="() => openModal()"
                ></vi-button-spinner>
            </div>
        </div>

        <div class="cg-coming-soon">
            Coming {{ formartDate(info.addon.start_date)}}<span v-if="!signedUp">, Sign up now</span>
        </div>

        <div v-if="signedUp" class="modal fade" ref="cancelModal" id="cancelModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div class="modal-body">
                        <p>Are you sure you want to opt out of Connect and Grow?</p>
                    </div>

                    <div class="modal-footer">
                        <button
                            :disabled="loading"
                            type="button"
                            class="new-btn new-btn-dark fs-small fw-normal text-capitalize"
                            data-bs-dismiss="modal"
                        >No, I don't</button>

                        <vi-button-spinner
                            :buttonClass="{
                                'new-btn': true,
                                'new-btn-dark': true,
                                'text-capitalize': true,
                                'fs-small': true,
                                'new-btn-ghost': true,
                                'fw-normal': true,
                                'cac-cancel-btn': true,
                            }"
                            :buttonText="'Yes, I do'"
                            :loading="loading"
                            @button-click="() => cancel()"
                        ></vi-button-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name: "ConnectAndGrow",
    data() {
        return {
            loading: false,
            signedUp: false,
            signedUpSuccess: false,
            modal: null,
        }
    },
    props: {
        studentId: {
            type: String,
            required: true
        },
        info: {
            type: Object,
            required: true
        },
        alreadySignedUp: {
            type: Boolean,
            required: false
        },
    },
    mounted() {
        this.signedUp = this.info.alreadySignedUp;
    },

    methods: {
        signup() {
            this.loading = true;

            axios.post(this.info.signupUrl, {
                studentId: this.studentId
            }).then(response => {
                this.loading = false;
                this.signedUp = true;
                this.signedUpSuccess = true;
            }).catch(error => {
                this.loading = false;
            });
        },
        formartDate(date) {
            return moment(date).format('MMMM YYYY');
        },
        cancel() {
            this.loading = true;
            axios.post(this.info.cancelUrl, {
                studentId: this.studentId
            }).then(response => {
                this.loading = false;
                this.signedUp = false;
                this.signedUpSuccess = false;

                if (this.modal) {
                    this.modal.hide();
                }
            }).catch(error => {
                this.loading = false;
            });
        },
        openModal() {
            this.modal = new bootstrap.Modal(this.$refs.cancelModal);

            this.modal.show();
        }
    }
}
</script>
<style lang="scss" scoped>
 @import '../../../sass/abstract/colours.scss';


.cg {
    $padding: 16px;

    background-color: $white;
    border-radius: 32px;
    overflow: hidden;

    display: grid;
    grid-template-areas: "header header"
                         "img info"
                         "coming-soon coming-soon";
    grid-template-columns: 40% 60%;

    @media screen and (max-width: 1264px) {
        grid-template-areas: "header"
                             "img"
                             "info"
                             "coming-soon";
        grid-template-columns: 100%;

    }

    &-header {
        background-color: $pastel-blue;
        padding: $padding;
        color: $white;
        font-weight: 600;
        font-size: 20px;
        display: block;
        text-decoration: none;
        grid-area: header;
        transition: background-color 200ms ease;

        &:hover {
            background-color: $pastel-light-blue;
        }
    }

    &-img {
        grid-area: img;
        padding: $padding;

        img {
            width: 100%;
            max-width: 324px;
            display: block;
            margin: 0 auto;
            filter: blur(4px);
        }
    }

    &-info-container {
        grid-area: info;
        padding: $padding;
        justify-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-description {
        text-align: center;
    }

    &-info-link {
        text-align: center;
        margin-bottom: 2em;
    }

    &-coming-soon {
        grid-area: coming-soon;
        padding: $padding;
        color: $pastel-blue;
        text-align: center;
        font-weight: 600;
        position: relative;

        &::after {
            content: '';
            display: block;
            height: 2px;
            width: calc(100% - ( #{$padding} * 2));
            background-color: $pastel-blue;
            opacity: 0.2;

            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}

body .new-btn-dark.new-btn-ghost.cac-cancel-btn {
    color: $pastel-blue;

    &:hover {
        color: $white;
    }
}
</style>
