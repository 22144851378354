<template>

    <div ref="modal" class="modal fade" :id="`guardiansSupportCoachBookingModal-${studentId}`" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="guardiansSupportCoachBookingModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl vi-modal-dialog-extra-height">
            <div class="modal-content vi-modal-dialog-extra-height">
                <div class="modal-header">
                    <h5 class="modal-title" id="guardiansSupportCoachBookingModalLabel">{{ headingText }}</h5>
                    <div class="d-flex flex-row align-items-center">
                        <button type="button" class="btn-close mx-0" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                </div>
                <div class="modal-body">
                    <iframe :src="iframeUrl" style="height:100%; width:100%; padding: 1em;"></iframe>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "SupportCoachBooking",
    data: () => ({
        isOpen: false,
    }),
    props: {
        studentId: {
            type: String,
            required: true,
        },
        bookingLink: {
            type: String,
            required: true,
        },
        headingText: {
            type: String,
            required: true,
        },
        isProduction: {
            type: Boolean,
            required: true,
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', event => {
            this.isOpen = true;
        });

        this.$refs.modal.addEventListener('hide.bs.modal', event => {
            this.isOpen = false;
        });
    },
    computed: {
        iframeUrl() {
            return this.isOpen ? this.bookingLink : '';
        }
    }
}
</script>
