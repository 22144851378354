<template>

    <div
        :id="`block-${student.id}`"
        class="accordion-collapse py-4"
        :class="{'collapse': !isExpanded, 'show' : isExpanded}"
        :aria-labelledby="`heading-${student.id}`"
        data-bs-parent="#learnerAccordian"
        ref="body"
    >
        <div class="accordion-body">

            <div v-if="!loading && isPaused" class="row">
                <div class="col">
                    <div class="alert alert-info m-0">
                        <template v-if="student.unpauseDate">Your learning has been paused until {{ student.unpauseDate }}. Please visit the <a :href="accountUrl">'My Account'</a> section to unpause it now</template>
                        <template v-else>Your learning has been paused. Please visit the <a :href="accountUrl">'My Account'</a> section to unpause it now</template>
                    </div>
                </div>
            </div>

            <div class="row widget-row">
                <p v-if="errorMessage"> {{ errorMessage }}</p>

                <div v-else class="col-12">

                    <div v-if="loading" class="loader d-block m-auto">
                        <span class="visually-hidden"> Loading...</span>
                    </div>

                    <div v-else-if="loadedData">
                        <template v-if="viewConfig.affiliationStatus === 'Upcoming'">
                            <p>Your student's enrolment is still upcoming</p>
                        </template>

                        <template v-else-if="viewConfig.affiliationStatus === 'Complete'">
                            <section class="student-details row">
                                <template v-for="(card, key) in filteredActionCards">
                                    <dashboard-action-card
                                        v-if="!isActionCardHidden(card.heading)"
                                        :class="{
                                            'col-12': true,
                                            'col-md-6': true,
                                            'mt-3': true
                                        }"
                                        :student-email="student.studentEmail"
                                        :heading=card.heading
                                        :action=card.actionButtonTitle
                                        :is-modal="card.isModal ?? false"
                                        :is-link="card.isLink ?? false"
                                        :url="card.urlLink ?? null"
                                        :id="key"
                                        :key="key"
                                    > </dashboard-action-card>
                                </template>

                                <reports
                                    :header-prefix="`Reports`"
                                    :reports="loadedData.reports"
                                    :class="{ 'mb-3': !(curriculum === 'caps') }"
                                ></reports>
                            </section>
                        </template>

                        <!-- Affiliation Status is Active at this point. -->
                        <template v-else>
                            <template v-if="viewConfig.programEnrollmentStatus === 'Upcoming'">
                                <p>We're excited to welcome {{ student.name }} on {{ (new Date(student.affiliation.hed__StartDate__c)).toLocaleDateString() }}. This section will become active once your learner is enrolled. You'll then be able to:</p>

                                <ol>
                                    <li>View progress and detailed marks</li>
                                    <li>View upcoming assessments and exam schedules</li>
                                    <li>Query grades and apply for excusals</li>
                                    <li>View reports</li>
                                    <li>Contact support coaches and view facilitators</li>
                                </ol>

                                <connect-and-grow
                                    v-if="loadedData.connectAndGrow"
                                    :student-id="student.id"
                                    :info="loadedData.connectAndGrow"
                                ></connect-and-grow>
                            </template>

                            <template v-else-if="viewConfig.programEnrollmentStatus === 'Currently_Enrolled' || viewConfig.programEnrollmentStatus === 'Error_And_Enrolled' || viewConfig.programEnrollmentStatus === 'Suspended' ">

                                <section class="student-details row">

                                    <div class="col-12" v-if="student.hasOrIsPendingSuspension">
                                       <vi-alert :type="`vi-error`">
                                            This learner is pending financial suspension or may have already been suspended as you have one or more outstanding invoices. Please visit the Invoices section on <a href="/uct/guardians/account#billingDetailsAccordion">My Account</a> for more information or contact our <a href="mailto:finance@valentureinstitute.com">Finance Team</a> for support.
                                       </vi-alert>
                                    </div>

                                    <!-- new progress card component -->
                                    <div v-if="curriculum === 'caps' || curriculum === 'caie'" class="col-12">
                                        <termly-progress
                                            v-on:refresh="onRefresh"
                                            v-on:view-more="onViewMore"
                                            :formal-assessments="loadedData.formalAssessments"
                                            :student-email="student.studentEmail"
                                        ></termly-progress>

                                        <connect-and-grow
                                            v-if="loadedData.connectAndGrow"
                                            :student-id="student.id"
                                            :info="loadedData.connectAndGrow"
                                        ></connect-and-grow>

                                    </div>

                                    <div v-else class="col-12 col-md-6">
                                        <pacing-data
                                            :student-email="student.studentEmail"
                                            :student-id="student.id"
                                            :current-program-enrolment="loadedData.currentProgramEnrolment"
                                            :initial-pace-data="loadedData.pacingData"
                                            :fetch-pace-url="loadedData.fetchPaceUrl"
                                            :is-test-site="!isProduction"
                                        >
                                        </pacing-data>
                                    </div>

                                    <div :class="{
                                        'col-md-6': curriculum === 'adult-matric',
                                        'col-12': curriculum === 'adult-matric',
                                        'spacing-above-children-3': curriculum === 'adult-matric',
                                        'row': curriculum !== 'adult-matric',
                                        'pt-3': curriculum !== 'adult-matric',
                                    }">

                                        <progression-application
                                            v-if="loadedData.progressionApplication && loadedData.progressionApplication.subjectSelectionActive"
                                            :progression-application="loadedData.progressionApplication"
                                            :class="{
                                                'col-12': curriculum !== 'adult-matric',
                                                'col-md-6': curriculum !== 'adult-matric',
                                                'mb-3': !curriculum === 'adult-matric'
                                            }"
                                        >
                                        </progression-application>

                                        <apply-for-lls
                                            v-if="loadedData.progressionApplication && loadedData.progressionApplication.canAccessScholarshipsPage && viewConfig.programEnrollmentStatus != 'Suspended'"
                                            :progression-application="loadedData.progressionApplication"
                                            :class="{
                                                'col-12': curriculum !== 'adult-matric',
                                                'col-md-6': curriculum !== 'adult-matric',
                                                'mb-3': !curriculum === 'adult-matric'
                                            }"
                                        >
                                        </apply-for-lls>

                                        <template v-if="loadedData.actionCards">
                                            <template v-for="(card, key) in loadedData.actionCards">
                                                <dashboard-action-card
                                                    v-if="!isActionCardHidden(card.heading)"
                                                    :class="{
                                                        'col-12': curriculum !== 'adult-matric',
                                                        'col-md-6': curriculum !== 'adult-matric',
                                                        'mb-3': curriculum === 'adult-matric'
                                                    }"
                                                    :student-email="student.studentEmail"
                                                    :heading=card.heading
                                                    :action=card.actionButtonTitle
                                                    :is-modal="card.isModal ?? true"
                                                    :is-link="card.isLink ?? false"
                                                    :url="card.urlLink ?? null"
                                                    :id="key"
                                                    :key="key"
                                                > </dashboard-action-card>
                                            </template>
                                        </template>

                                        <reports
                                            :header-prefix="`Reports`"
                                            :reports="loadedData.reports"
                                            :class="{ 'mb-3': !(curriculum === 'caps') }"
                                        ></reports>

                                        <teachers
                                            :teachers="loadedData.teachers"
                                            :student-id="student.id"
                                            :student-name="student.name"
                                            :is-caie="loadedData.isCaie"
                                            :class="{ 'mb-3': !(curriculum === 'caps') }"
                                        ></teachers>

                                        <support-coach
                                            v-if="loadedData.guardian"
                                            :student-id="student.id"
                                            :support-coach="loadedData.supportCoach"
                                            :dial-codes="dialCodes"
                                            :is-adult-matric="loadedData.isAdultMatric"
                                            :guardian="loadedData.guardian"
                                            :class="{ 'mb-3': !(curriculum === 'caps') }"
                                        ></support-coach>

                                        <div
                                            v-if="loadedData.supportCoach.email && !loadedData.isAdultMatric"
                                            :class="{ 'mb-3': !(curriculum === 'caps') }"
                                        >
                                            <support-coach-booking
                                                :is-production="isProduction"
                                                :student-id="student.id"
                                                :booking-link="loadedData.supportCoach.booking_link"
                                                heading-text="Book a meeting"
                                            ></support-coach-booking>
                                        </div>

                                        <upcoming-exams-modal
                                            :exams-data="loadedData.examsData"
                                            :looker-url="loadedData.lookerUrls.upcomingExams"
                                            :student="student"
                                            :is-production="isProduction"
                                        ></upcoming-exams-modal>

                                        <live-session-time-table-modal
                                            :student-id="student.id"
                                            :url="liveSessionTimeTableUrl"
                                            :is-production="isProduction"
                                        ></live-session-time-table-modal>
                                    </div>
                                </section>
                            </template>

                            <template v-else-if="viewConfig.programEnrollmentStatus === 'Withdrawn'">
                                <div
                                    :class="{
                                        'col-md-6': true,
                                        'col-12': true,
                                    }"
                                >
                                    <reports
                                        :header-prefix="`Reports`"
                                        :reports="loadedData.reports"
                                        :class="{ 'mb-3': !(curriculum === 'caps') }"
                                    ></reports>
                                </div>

                                <template v-for="(card, key) in filteredActionCards">
                                    <dashboard-action-card
                                        v-if="!isActionCardHidden(card.heading)"
                                        :class="{
                                            'col-12': true,
                                            'col-md-6': true,
                                            'mt-3': true
                                        }"
                                        :student-email="student.studentEmail"
                                        :heading=card.heading
                                        :action=card.actionButtonTitle
                                        :is-modal="card.isModal ?? false"
                                        :is-link="card.isLink ?? false"
                                        :url="card.urlLink ?? null"
                                        :id="key"
                                        :key="key"
                                    > </dashboard-action-card>
                                </template>
                            </template>

                            <template v-else-if="viewConfig.programEnrollmentStatus === 'Enrolled_Not_Active'">
                                <p class="mb-3">We're excited to welcome {{ student.name }} on {{ (new Date(student.affiliation.hed__StartDate__c)).toLocaleDateString() }}. This section will become active once your learner is enrolled. In the meantime, you can view past reports:</p>

                                <div :class="{
                                        'col-md-6': true,
                                        'col-12': true,
                                    }">
                                    <reports
                                        :header-prefix="`Reports`"
                                        :reports="loadedData.reports"
                                        :class="{ 'mb-3': !(curriculum === 'caps') }"
                                    ></reports>
                                </div>

                                <template v-for="(card, key) in filteredActionCards">
                                    <dashboard-action-card
                                        v-if="!isActionCardHidden(card.heading)"
                                        :class="{
                                            'col-12': true,
                                            'col-md-6': true,
                                            'mt-3': true
                                        }"
                                        :student-email="student.studentEmail"
                                        :heading=card.heading
                                        :action=card.actionButtonTitle
                                        :is-modal="card.isModal ?? false"
                                        :is-link="card.isLink ?? false"
                                        :url="card.urlLink ?? null"
                                        :id="key"
                                        :key="key"
                                    > </dashboard-action-card>
                                </template>
                            </template>

                            <template v-else-if="viewConfig.programEnrollmentStatus === 'Error'">
                                <div class="alert alert-danger">
                                    An error occured if this error persists please contact technical support.
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import ConnectAndGrow from './ConnectAndGrow.vue';


    export default {
        name: "Student",
        components: {
            'connect-and-grow': ConnectAndGrow,
        },

        data: () => ({
            loadedData: null,
            loading: true,
            errorMessage: null,
        }),

        props: {
            student: {
                type: Object,
                required: true,
                default: () => ({}),
            },
            dialCodes: {
                type: Object,
                required: true,
                default: () => ({}),
            },
            url: {
                type: String,
                reqired: true,
            },
            isProduction: {
                type: Boolean,
                required: true,
                default: true
            },
            isExpanded: {
                type: Boolean,
                default: false
            },
            isMobileView: {
                type: Boolean,
                default: false
            },
            curriculum: {
                type: String,
            },
            viewConfig: {
                type: Object,
                required: true,
            },
            liveSessionTimeTableUrl: {
                type: String,
                required: true,
            },
            isPaused: {
                type: Boolean,
                required: true,
            },
            accountUrl: {
                type: String,
                required: true,
            }
        },

        mounted() {
            if (this.isExpanded) {
                this.initialize();
            }

            this.$refs.body.addEventListener('show.bs.collapse', event => {
                if (!this.loadedData) {
                    this.initialize();
                }
            });
        },
        methods: {
            async onRefresh(data) {
                await this.getData(`${this.url}&ignore_cache=1`);
            },
            async getData(overrideUrl = null) {
                this.loading = true;

                try {
                    var response = await axios.get(overrideUrl ? overrideUrl : this.url);

                    if (response.hasOwnProperty('data') && response.data.hasOwnProperty('data')) {
                        this.loadedData = response.data.data;
                    }

                } catch(error) {
                    this.errorMessage = "We had an unexpected error loading this student's data. Please try refreshing the page or contact us for support.";
                    console.error(error);
                    this.loading = false
                    return;
                }

                this.loading = false;
            },
            isActionCardHidden(cardHeading) {
                return this.isMobileView && cardHeading == 'Academic Reports';
            },
            async initialize() {
                await this.getData();

                let studentElement = document.getElementById(`block-${this.student.id}`);

                let paceDataGradesTargets = studentElement.querySelectorAll('[data-bs-target="#paceDataGrades"]:not(.view-more-button)');
                let weeklySummaryTargets = studentElement.querySelectorAll('[data-bs-target^="#weekly-attendance"]');
                let upcomingAssignmentsTargets = studentElement.querySelectorAll('[data-bs-target="#upcoming-assignments"]');

                paceDataGradesTargets.forEach(element => {
                    element.addEventListener('click' , (event) => {
                        const subjectNameEl = event.target.parentElement.querySelector('.subject-name');
                        let subjectName = subjectNameEl ? subjectNameEl.innerText : null;
                        let studentEmail = event.target.dataset['studentEmail'];

                        let openGradingQueries = event.target.classList.contains('widget-card-action');

                        const lookerEvent = new CustomEvent('vi-looker.open', {
                            detail: {
                                studentEmail,
                                subjectName,
                                id: 'assessment-submissions',
                                openGradingQueries,
                            }
                        });

                        // Dispatch the event1.
                        document.body.dispatchEvent(lookerEvent);
                    });
                });


                weeklySummaryTargets.forEach(element => {
                    element.addEventListener('click' , (event) => {
                        let studentEmail = event.target.dataset['studentEmail'];

                        let moduleNumber = event.target.dataset['moduleNumber'] ?? 0;

                        const lookerEvent = new CustomEvent('weekly-attendance.open', {
                            detail: {
                                moduleNumber: moduleNumber,
                                studentEmail: studentEmail,
                            }
                        });

                        // Dispatch the event.
                        document.body.dispatchEvent(lookerEvent);
                    });
                });

                upcomingAssignmentsTargets.forEach(element => {
                    element.addEventListener('click' , (event) => {
                        let studentEmail = event.target.dataset['studentEmail'];

                        const lookerEvent = new CustomEvent('vi-looker.open', {
                            detail: {
                                studentEmail,
                                id: "upcoming-assignments",
                            }
                        });

                        // Dispatch the event.
                        document.body.dispatchEvent(lookerEvent);
                    });
                });

                $('.viLookerPaceDataTriggerStudent').on('click', function(event) {
                    let subjectName = this.dataset['viLookerSubjectName']; // data-vi-looker-subject-name
                    let studentEmail = this.dataset['viLookerStudentEmail'];

                    const lookerEvent = new CustomEvent('vi-looker.open', {
                        detail: {
                            subjectName,
                            studentEmail,
                            id: 'assessment-submissions',
                        }
                    });

                    // Dispatch the event.
                    document.body.dispatchEvent(lookerEvent);
                });
            },
            onViewMore(data) {
                const lookerEvent = new CustomEvent('vi-looker.open', {
                    detail: {
                        subjectName: data.subject,
                        studentEmail: this.student.studentEmail,
                        id: 'assessment-submissions',
                    }
                });

                // Dispatch the event.
                document.body.dispatchEvent(lookerEvent);
            },
            async onAdminReload(event) {
                let url = new URL(this.url);
                let value = event.target.sfId.value;

                url.searchParams.set('student_id', value);
                url.searchParams.set('ignore_cache', 1);

                await this.getData(url.toString());
            },
            hasReports(reports) {
                if (reports.programEnrolments.length < 1) {
                    return false;
                }

                let hasReports = false;

                reports.programEnrolments.forEach(programEnrolment => {
                    hasReports = hasReports || programEnrolment.reports.length > 1;
                });

                return hasReports;
            }
        },
        computed: {
            filteredActionCards() {
                let filtered = {};
                let reportsKey = Object.keys(this.loadedData.actionCards).find(
                    key => this.loadedData.actionCards[key].heading === 'Academic Reports'
                );

                if (reportsKey !== undefined) {
                    filtered[reportsKey] = this.loadedData.actionCards[reportsKey];
                }

                let manageKey = Object.keys(this.loadedData.actionCards).find(
                    key => this.loadedData.actionCards[key].heading === 'Manage Assessments'
                );

                if (manageKey !== undefined) {
                    filtered[manageKey] = this.loadedData.actionCards[manageKey];
                }

                // reapply key
                let reapplyForAdultMatricKey = Object.keys(this.loadedData.actionCards).find(
                    key => this.loadedData.actionCards[key].heading === 'Re-join Adult Matric Prep Programme'
                );

                if (reapplyForAdultMatricKey !== undefined) {
                    filtered[reapplyForAdultMatricKey] = this.loadedData.actionCards[reapplyForAdultMatricKey];
                }

                return filtered;
            }
        }
    }
</script>

<style lang="scss" scoped>
.refresh-button {
    &:hover {
        cursor: pointer;
    }
}

.form-switch {
    z-index: 100;
}
</style>
